import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

export const FilmBg = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 47vh;
  z-index: 998;
  .pic {
    min-height: 100%;
    min-width: 100%;
    object-position: center;
  }

  .pic-container {
    width: calc(100vw / 3);
    height: 47vh;
    margin: 0;
    padding: 0;
  }
  @media (max-height: 600px) {
    height: 43vh;
    .pic-container {
      height: 43vh;
    }
    img {
      height: 39vh;
    }
  }
  @media (max-height: 500px) {
    height: 39vh;
    .pic-container {
      height: 39vh;
    }
    img {
      height: 39vh;
    }
  }
`
