import React from "react"
import { Link } from "gatsby"

import styled, { keyframes } from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"

const FadeIn = keyframes`
  from {
   opacity: 0;
  }

  to {
    opacity:1;
  }
`

const StyledIndex = styled.div`
  display: none;
  @media (max-width: 799px) {
    display: block;
    opacity: 0;
    animation: ${FadeIn} 0.4s 1s forwards;
  }
  font-weight: bold;

  z-index: 999;
  isolation: isolate;

  position: relative;
  max-width: 100%;
  width: 100vw;
  height: 100vh;
  @media (max-height: 599px) {
    transform: scale(0.9);
    height: 130vw;
  }
  transition: height 0.5s;
`

const StyledFilms = styled.div`
  display: flex;

  flex-direction: column;
  justify-content: center;
  font-size: 5.5vw;
  text-align: left;

  height: 100%;
  ul {
    width: 86vw;
    overflow: hidden;
    margin: auto 7vw;
    padding-bottom: 55vh;
  }
  li {
    line-height: 2;
  }
  .film-title {
    overflow-x: hidden;
    background-color: white;
  }
`

const ContactBtn = styled.button`
  position: absolute;
  z-index: 999;
  width: 21vw;
  left: 39.5vw;
  right: 39.5vw;
  bottom: 20vh;
  transition: bottom 1s ease;
  @media (max-height: 579px) {
    bottom: 12vh;
  }
  font-size: 5.5vw;
  line-height: 6vw;

  background: none;
  border-bottom: 1px solid white !important;
  border: none;
  padding: 0;
  text-align: center;
`

export default function FilmsMobile(props) {
  return (
    <StyledIndex mobileHeight={props.mobileHeight}>
      <ContactBtn id="home-link" onClick={() => scrollTo("#contact-mobile")}>
        Contact
      </ContactBtn>
      <StyledFilms id="home">
        <ul id="film-list">
          <li>
            <Link to="/audemars-piguet">
              <span className=" no-trema film-title">Audemars Piguet</span>
            </Link>
          </li>
          <li>
            <Link to="/travis-scott-gods-country">
              <span className=" no-trema film-title">Travis Scott</span>
            </Link>
          </li>
          <li>
            <Link to="/mia-popular">
              <span className=" no-trema film-title">M.I.A</span>
            </Link>
          </li>
          <li>
            <Link to="/kanye-balenciaga-gap">
              <span className=" no-trema film-title">Gap x Balenciaga</span>
            </Link>
          </li>
          <li>
            <Link to="/kanye-heaven-hell">
              <span className=" no-trema film-title">Kanye West</span>
            </Link>
          </li>
          <li>
            <Link to="/laylow-nike-vapormax">
              <span className=" no-trema film-title">Laylow x Nike</span>
            </Link>
          </li>
          <li>
            <Link to="/beadadoobee-last-day-on-earth">
              <span className=" no-trema film-title">Beadadoobee</span>
            </Link>
          </li>
          <li>
            <Link to="/flatbush-zombies-james-blake-afterlife">
              <span className=" no-trema film-title">Flatbush Zombies</span>
            </Link>
          </li>
          <li>
            <Link to="/rl-grime-arcus">
              <span className=" no-trema film-title">RL Grime</span>
            </Link>
          </li>
          <li>
            <Link to="/kanye-hurricane">
              <span className=" no-trema film-title">
                Kanye West ft. The Weeknd
              </span>
            </Link>
          </li>
        </ul>
      </StyledFilms>
    </StyledIndex>
  )
}
